<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="zu_tree" v-if="tree_zhanshi">
        <div class="zu_tree_con">
            <b class="zu_tree_tit">
                {{title}}
            </b>
            <div class="zu_tree_box">
                <el-tree :data="neirong_list" ref="jiegou_tree"  node-key="id" @check-change="xuanze" :check-strictly='guanlian=="没关联"?true:false' :default-checked-keys="yixuan_id" default-expand-all show-checkbox :props="defaultProps"></el-tree>
            </div>
            <div class="foote">
                <p class="queding" @click="tree_true">确定</p>
                <p class="quxiao" @click="tree_false">取消</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'tree',
  data () {
    return {
      tree_zhanshi: false, //
      title: '', // 标题
      neirong_list: [], // 树形图
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      yixuan_id: [],
      dan_duoxuan: '', // 判断是单选还是多选
      guanlian: ''// 判断是否有关联
    }
  },
  mounted () {
  },
  created () {

  },
  watch: {

  },
  methods: {
    jichu (date, name, yixuan, dan_duoxuan, guanlian) {
      this.tree_zhanshi = true
      this.yixuan_id = yixuan.length != 0 ? yixuan.map(item => item.id) : []
      console.log(this.yixuan_id)
      this.neirong_list = date
      this.title = name
      this.dan_duoxuan = dan_duoxuan
      this.guanlian = guanlian
    },
    tree_true () {
      const date = this.$refs.jiegou_tree.getCheckedNodes()
      date.forEach(item => {
        this.yixuan_id.forEach(ite => {
          if (item.id == ite) {
            this.$set(item, 'yiyou', true)
          }
        })
      })
      console.log(date)
      this.$emit('shuxing', date)
      this.tree_zhanshi = false
      this.neirong_list = []
      this.title = ''
    },
    tree_false () {
      this.tree_zhanshi = false
      this.neirong_list = []
      this.title = ''
    },
    xuanze (data, checked, node) {
      if (this.dan_duoxuan == '单选') {
        if (checked === true) {
          this.checkedId = data.id
          this.$refs.jiegou_tree.setCheckedKeys([data.id])
        } else {
          if (this.checkedId == data.id) {
            this.$refs.jiegou_tree.setCheckedKeys([data.id])
          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './zujian.scss';
</style>
