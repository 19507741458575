<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="inventory">
        <div class="purchasing_tit">
            <p></p>
            <p>库存管理</p>
            <p>
                <span>
                    (数量，金额)
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                单位：{{danwei}}
            </p>
        </div>
        <div class="purchasing_box">
            <div class="purchasing_box_left">
                <el-date-picker
                    v-model="time"
                    class="purchasing_box_left_sj"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
                <div>
                    产品
                    <el-tooltip class="item" effect="dark" :content="dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')" placement="top-start">
                        <p @click="dianji_xuanze_chanpin">{{dangqian_xuanze_chanpin.length!=0?(dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')):'请选择'}}</p>
                    </el-tooltip>
                </div>
                <!-- <div>
                    供应商
                    <el-tooltip class="item" effect="dark" :content="dangqian_gongying_shang.map(item=>item.supply_name).join(',')" placement="top-start">
                        <p @click="dianji_xuanze_gongying_shang(null)">{{dangqian_gongying_shang.length!=0?(dangqian_gongying_shang.map(item=>item.supply_name).join(',')):'请选择'}}</p>
                    </el-tooltip>
                </div> -->
                <div>
                    仓库
                    <el-tooltip class="item" effect="dark" :content="dangqian_xuanze_cangku.map(item=>item.label).join(',')" placement="top-start">
                        <p @click="dianji_xuanze_cangku">{{dangqian_xuanze_cangku.length!=0?(dangqian_xuanze_cangku.map(item=>item.label).join(',')):'请选择'}}</p>
                    </el-tooltip>
                </div>
            </div>
            <div class="purchasing_box_right">
                <p @click="dianji_jinru_kucun">库存</p>
            </div>
        </div>
        <div class="purchasing_con">
            <div id="caigou_guanli_tu" :style="{width: '100%', height: '100%'}"></div>
        </div>
        <ul class="purchasing_lizi">
            <li><span class="ruku_color"></span>入库</li>
            <li><span class="chuku_color"></span>出库</li>
            <li><span class="kucun_color"></span>库存</li>
        </ul>
        <shaixuan ref="xuanze" @sousuo='sousuo_chanpin' @dalei='dianji_dalei' @shaixuan_queding='dianji_queding'></shaixuan>
        <zujianDuoxuan ref="cangku" @shuxing='chuanzhi'></zujianDuoxuan>
        <div class='beijing' v-if='chanpin' @click="chanpin=false">
            <div class="beijing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit'>
                    产品分类：
                    <div class="beijing_box_tit_div">
                        <p class="beijing_box_tit_p">{{dangqian_chanpin.label}}</p>
                        <div class="beijing_box_tit_tree">
                            <el-tree :data="qiye_chanpin_leixing_tree" :props="tree_guize" @node-click="xuanze_chanpin_dalei"></el-tree>
                        </div>
                    </div>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for='(cp,ind) in dangqian_mingxi_kuang_chanpin' :key="cp.prod_code">
                        <i @click="shanchu_yixuan_chanpin(cp,ind)" class="el-icon-error"></i>
                        {{cp.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi" :data="chanpin_liebiao_list"
                        highlight-current-row
                        ref="Tablea"
                        style="width: 100%"
                        @select = "xuanze_chanpin"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shaixuan from '../../components/shaixuan/Duoxuan'
import zujianDuoxuan from '../../components/zujian/Tree'
import { query_all_prod_list_of_category, query_ent_prod_cat_list_tree, query_ent_site_tree, query_supply_list, query_category_info_list, query_prod_info_list, get_prod_list_like, generate_stock_mng_bar_chart } from '../../api/api'
export default {
  name: 'inventory',
  data () {
    return {
      danwei: '元',
      time: '', // 时间区间
      ruku_jine: '', // 入库金额
      kucun_jine: '', // 库存金额
      chuku_jine: '', // 出库金额
      ruku_shuliang: '',
      chuku_shuliang: '',
      kucun_shuliang: '',

      dangqian_xuanze_chanpin: [], // 当前所选产品
      dangqian_gongying_shang: [], // 当前所选供应商
      dangqian_xuanze_cangku: [],

      // 产品选择修改选择样式    2020、11、11   啊哈哈  光棍节快乐啊QwQ
      chanpin: false,
      dangqian_chanpin: {
        label: '',
        id: ''
      },
      qiye_chanpin_leixing_tree: [], // 产品大类树
      sousuo_chanpin_text: '', // 搜索产品text
      chanpin_xuanze: '',
      chanpin_liebiao_list: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      dangqian_mingxi_kuang_chanpin: []
    }
  },
  created () {
  },
  mounted () {
    this.jichu()
  },
  components: { shaixuan, zujianDuoxuan },
  watch: {
    time () {
      this.liebiao_jiekou()
    },
    dangqian_xuanze_chanpin () {
      this.liebiao_jiekou()
    },
    dangqian_gongying_shang () {
      this.liebiao_jiekou()
    },
    dangqian_xuanze_cangku () {
      this.liebiao_jiekou()
    },
    chanpin () {
      if (!this.chanpin) {
        this.dangqian_chanpin = {
          label: '',
          id: ''
        }
        this.sousuo_chanpin_text = ''
        this.chanpin_xuanze = ''
        this.chanpin_liebiao_list = []
        this.dangqian_mingxi_kuang_chanpin = []
      }
    }
  },
  methods: {
    jichu () {
      this.liebiao_jiekou()
    },
    liebiao_jiekou () {
      console.log(this.dangqian_xuanze_cangku)

      generate_stock_mng_bar_chart({
        data: {
          ent_id: this.$ent_id(),
          prod_ids: this.dangqian_xuanze_chanpin.length != 0 ? this.dangqian_xuanze_chanpin.map(item => item.prod_id).join(',') : null,
          site_ids: this.dangqian_xuanze_cangku.length != 0 ? this.dangqian_xuanze_cangku.map(item => item.id).join(',') : null,
          supply_ids: this.dangqian_gongying_shang.length != 0 ? this.dangqian_gongying_shang.map(item => item.supply_id).join(',') : null,
          date_start: this.time.length != 0 ? this.time[0].getFullYear() + '-' + this.$func.pa(this.time[0].getMonth() + 1) + '-' + this.$func.pa(this.time[0].getDate()) : null,
          date_end: this.time.length != 0 ? this.time[1].getFullYear() + '-' + this.$func.pa(this.time[1].getMonth() + 1) + '-' + this.$func.pa(this.time[1].getDate()) : null
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          let chu_zhi = 1
          if (Number(date.amt_in_store) >= 100000000 || Number(date.amt_out_store) >= 100000000 || Number(date.amt_stock) >= 100000000) {
            this.danwei = '亿元'
            chu_zhi = 100000000
          } else if (Number(date.amt_in_store) >= 10000 || Number(date.amt_out_store) >= 10000 || Number(date.amt_stock) >= 10000) {
            this.danwei = '万元'
            chu_zhi = 10000
          } else {
            this.danwei = '元'
          }
          this.ruku_jine = date.amt_in_store != undefined ? (Number(date.amt_in_store) / chu_zhi).toFixed(2) : 0 // 已入库
          this.ruku_shuliang = date.qty_in_store
          this.chuku_jine = date.amt_out_store != undefined ? (Number(date.amt_out_store) / chu_zhi).toFixed(2) : 0 // 未入库
          this.chuku_shuliang = date.qty_out_store
          this.kucun_jine = date.amt_stock != undefined ? (Number(date.amt_stock) / chu_zhi).toFixed(2) : 0 // 已付款
          this.kucun_shuliang = date.qty_stock
          this.canver()
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
    },
    // 第一幅图
    canver () {
      var echarts = require('echarts')
      var myChart = echarts.init(document.getElementById('caigou_guanli_tu'))
      myChart.setOption({
        xAxis: {
          type: 'category',
          // data: ['入库','付款','开票'],
          axisLine: {
            lineStyle: {
              color: '#979797'
            }
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            margin: 20,
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#979797'
            }
          },
          axisLabel: {
            interval: 0,
            fontSize: 14,
            show: true,
            margin: 20,
            textStyle: {
              color: '#666666'
            }
          }
        },
        series: [
          {
            type: 'bar',
            stack: '值',
            barWidth: '40%',
            data: [
              {
                value: this.ruku_jine,
                name: '本期入库',
                itemStyle: {
                  color: '#CECECE'
                },
                label: {
                  show: true,
                  position: 'inside',
                  distance: 20,
                  fontSize: 16,
                  formatter: params => {
                    console.log(params)
                    return '本期入库 \n\n' + '(' + this.ruku_shuliang + '、' + params.data.value + ')'
                  }
                }
              },
              {
                value: -Number(this.chuku_jine),
                name: '本期出库',
                itemStyle: {
                  color: '#8D8E8F'
                },
                label: {
                  show: true,
                  position: 'inside',
                  distance: 20,
                  fontSize: 16,
                  formatter: params => {
                    console.log(params)
                    return '本期出库 \n\n' + '(' + this.chuku_shuliang + '、' + params.data.value + ')'
                  }
                }
              },
              {
                value: this.kucun_jine,
                name: '库存',
                itemStyle: {
                  color: '#9C1914'
                },
                label: {
                  show: true,
                  position: 'inside',
                  distance: 20,
                  fontSize: 16,
                  formatter: params => {
                    console.log(params)
                    return '库存 \n\n' + '(' + this.kucun_shuliang + '、' + params.data.value + ')'
                  }
                }
              }
            ]
          }
        ]
      })
      function jinru_liebiao (param) {
        window.location.href = '#/inventorytou'
      }
      myChart.on('click', jinru_liebiao)
    },
    // 点击选择产品
    dianji_xuanze_chanpin () {
      // this.chanpin_dalei(null)
      this.chanpin = true
      query_ent_prod_cat_list_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_chanpin_leixing_tree = date
        } else if (res.data.code == 500) {}
      })
      query_prod_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          cat_id: '0',
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          this.no_xuan_chanpin(date)
        } else if (res.data.code == 10140) {
        }
      })
      this.dangqian_mingxi_kuang_chanpin = this.dangqian_xuanze_chanpin
    },
    chanpin_dalei (parent_id, yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_xuanze_chanpin
      }
      query_category_info_list({
        data: {
          active: '1',
          ent_id: this.$ent_id(),
          parent_id: parent_id != null ? parent_id : '0',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10193) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.chanpin_xiaolei(parent_id, date, yixuan)
        } else if (res.data.code == 10194) {}
      })
    },
    chanpin_xiaolei (parent_id, parent, yixuan) {
      query_prod_info_list({
        data: {
          active: '1',
          cat_id: parent_id != null ? parent_id : '0',
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.xuanze.jichu('选择产品', parent, date, yixuan, 'cat_name', 'cat_id', 'prod_name', 'prod_id')
          this.$refs.xuanze.bangding('dangqian_xuanze_chanpin')
        } else if (res.data.code == 10140) {}
      })
    },
    sousuo_chanpin (val) {
      console.log(val.text)
      if (val.bangding_ziduan == 'dangqian_xuanze_chanpin') {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: val.text.length != 0 ? val.text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.$refs.xuanze.sousuo_xiaolei(date, 'prod_name', 'prod_id')
          } else if (res.data.code == 10032) {}
        })
      } else {
        query_supply_list({
          data: {
            ent_id: this.$ent_id(),
            search_str: val.text.length != 0 ? val.text : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            this.$refs.xuanze.sousuo_xiaolei(date, 'supply_name', 'supply_id')
          } else if (res.data.code == 500) {
            alert('查询供应商list报错')
          }
        })
      }
    },
    // 点击大类
    dianji_dalei (data) {
      this.chanpin_dalei(data.id, data.yixuan)
    },
    dianji_queding (val) {
      console.log(val)
      this[val.ziduan] = val.list
    },
    // 点击选择供应商
    dianji_xuanze_gongying_shang (yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_gongying_shang
      }
      this.gongying_shang_xiaolei(yixuan)
    },
    gongying_shang_xiaolei (yixuan) {
      query_supply_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.$refs.xuanze.jichu('选择供应商', null, date, yixuan, null, null, 'supply_name', 'supply_id')
          this.$refs.xuanze.bangding('dangqian_gongying_shang')
        } else if (res.data.code == 500) {
          alert('查询供应商list报错')
        }
      })
    },
    dianji_jinru_kucun () {
      this.$router.push('/inventorytou')
    },
    // 点击选择仓库
    dianji_xuanze_cangku () {
      query_ent_site_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.cangku.jichu(date, '仓库选择', this.dangqian_xuanze_cangku, '多选', '有关联')
        } else if (res.data.code == 500) {}
      })
    },
    chuanzhi (val) {
      console.log(val)
      this.dangqian_xuanze_cangku = val
      console.log(this.dangqian_xuanze_cangku)
    },
    no_xuan_chanpin (date) {
      this.chanpin_liebiao_list = date
      this.$forceUpdate()
    },
    // 2020、6、10更改产品样式   2020/11/10修改传值
    xuanze_chanpin_dalei (val) {
      console.log(val)
      this.dangqian_chanpin = val
      if (this.dangqian_chanpin.id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.dangqian_chanpin.id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            // let date=JSON.parse(res.data.body.data)
            console.log(this.buneng_xuan_chanpin)
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.no_xuan_chanpin(date)
            // var b = this.buneng_xuan_chanpin
            // let deal = (arr1, arr2, key) => {
            //     let arr3 = []
            //     for (let index = 0; index < arr1.length; index++) {
            //         let item = arr1[index]
            //         if (!arr2.some(v => v[key] === item[key])) {
            //             arr3.push(item)
            //         }
            //     }
            //     return arr3
            // }
            // console.log(deal(a,b,'prod_id'))
            // this.chanpin_liebiao_list=deal(a,b,'prod_id')
            // console.log(this.chanpin_liebiao_list)
            // this.$forceUpdate()
            if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
              this.$nextTick(function () {
                this.chanpin_liebiao_list.forEach(item => {
                  this.dangqian_mingxi_kuang_chanpin.forEach(ite => {
                    if (ite.prod_id == item.prod_id) {
                      console.log(item)
                      this.$refs.Tablea.toggleRowSelection(item)
                      // .forEach(i=>{
                      //     i.toggleRowSelection(item)
                      // })
                    }
                  })
                })
              })
            }
            this.$forceUpdate()
          } else if (res.data.code == 500) {}
        })
      }
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // for(let i=0;i<date.length;i++){
            //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
            //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
            //             date.splice(i,1)
            //             i=i-1
            //         }
            //     }
            // }
            // this.chanpin_liebiao_list=date
            // console.log(this.chanpin_liebiao_list)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
              // console.log(date)
              // console.log(this.buneng_xuan_chanpin)
              // for(let i=0;i<date.length;i++){
              //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
              //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
              //             date.splice(i,1)
              //             i=i-1
              //         }
              //     }
              // }
              // this.chanpin_liebiao_list=date
              // console.log(this.chanpin_liebiao_list)
              // this.$forceUpdate()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    },
    dianji_xuanze_chanpin_baocun () {
      console.log(this.dangqian_mingxi_kuang_chanpin)
      console.log(this.suoxuan_list)
      this.dangqian_xuanze_chanpin = this.dangqian_mingxi_kuang_chanpin
      this.chanpin = false
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    dianji_xuanzhong_chanpin (val) {
      this.chanpin_xuanze = val
    },

    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tablea.toggleRowSelection(ite)
        }
      })
      this.dangqian_mingxi_kuang_chanpin.splice(index, 1)
    },
    xuanze_chanpin (rows, row) {
      console.log(rows)
      console.log(row)
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
        if (ses) {
          this.dangqian_mingxi_kuang_chanpin.push(row)
        } else {
          for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == row.prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
            }
          }
        }
      } else {
        this.dangqian_mingxi_kuang_chanpin = rows
      }
      // return rows.length && rows.indexOf(row) !== -1
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      if (rows.length != 0) {
        rows.forEach(item => {
          this.dangqian_mingxi_kuang_chanpin.push(item)
        })
        this.dangqian_mingxi_kuang_chanpin = this.$func.Es5duplicate(this.dangqian_mingxi_kuang_chanpin, 'prod_id')
      } else {
        // chanpin_liebiao_list
        // this.dangqian_mingxi_kuang_chanpin
        for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
          // this.chanpin_liebiao_list.forEach(item=>{
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
          // })
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './chanpin.scss';
</style>
